// React-Hooks und notwendige Bibliotheken importieren
import React, { useState, useEffect } from 'react'; // State- und Lifecycle-Management
import Plot from 'react-plotly.js'; // Bibliothek für interaktive Diagramme
import Today from './today.js'; // Komponente für die Ansicht "Heute"
import Baukasten from './Baukasten.js'; // Weitere Ansicht: Baukasten
import * as d3 from 'd3'; // Datenvisualisierungs-Bibliothek
import './App.css'; // Stylesheet der App
import Windrose from './Windrose.js'; // Windrosen-Komponente
import TemperaturFeuchte from './TemperaturFeuchte.js'; // Ansicht: Temperatur und Feuchte
import NiederschlagStrahlung from './NiederschlagStrahlung.js'; // Ansicht: Niederschlag und Strahlung
import moment from 'moment'; // Datum-/Zeitformatierung
//import { fetchData } from './fetchData.js'; // Externe Funktion zum Abrufen von Wetterdaten
import { fetchData } from './fetchDataLokal.js'; // Externe Funktion zum Abrufen von Wetterdaten
import { sum, to } from 'mathjs'; // Mathematische Berechnungen
import { MailOutlined, AreaChartOutlined, CalendarOutlined, CloudOutlined, SunOutlined } from '@ant-design/icons'; // Ant Design Icons
import { Col, Row, Layout, Menu, theme } from 'antd'; // Ant Design Komponenten für Layout und UI

// Layout-Komponenten von Ant Design
const { Header, Content, Footer } = Layout;

// Menü-Items für die Navigation
const items = [
  { label: 'Heute', key: 'today', icon: <CalendarOutlined /> }, // Menüpunkt: Heute
  { label: 'Temperatur & Feuchte', key: 'temfeu', icon: <SunOutlined /> }, // Menüpunkt: Temperatur & Feuchte
  { label: 'Niederschlag & Strahlung', key: 'niestra', icon: <CloudOutlined /> }, // Menüpunkt: Niederschlag & Strahlung
  { label: 'Baukasten', key: 'kasten', icon: <AreaChartOutlined /> }, // Menüpunkt: Baukasten
  { label: 'Test', key: 'test', icon: <MailOutlined /> }, // Menüpunkt: Test
];

// Hauptkomponente der App
const App = () => {
  // Zugriff auf Farb- und Design-Token von Ant Design
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // React States für die aktive Registerkarte und die Wetterdaten
  const [activeTab, setActiveTab] = useState('today'); // Aktive Registerkarte
  const [data, setData] = useState({
    x: [], temperature: [], humidity: [], rain: [], wind: [], boen: []
  }); // Daten für Diagramme und Komponenten

  // Funktion, um die aktive Registerkarte zu ändern
  const handleTabChange = (e) => {
    setActiveTab(e.key); // Ändert den Zustand basierend auf dem gewählten Menüpunkt
  };

  // Testdaten für die Windrosen-Komponente
  const windroseData = [
    { wind_dir_bin: "N", wind_speed_bin: "<5 m/s", frequency: 3 },
    { wind_dir_bin: "N", wind_speed_bin: "5-8 m/s", frequency: 2 },
    { wind_dir_bin: "NE", wind_speed_bin: "<5 m/s", frequency: 4 },
    { wind_dir_bin: "NE", wind_speed_bin: "5-8 m/s", frequency: 5 },
    { wind_dir_bin: "E", wind_speed_bin: "8-10 m/s", frequency: 7 },
    { wind_dir_bin: "S", wind_speed_bin: ">10 m/s", frequency: 6 },
    { wind_dir_bin: "W", wind_speed_bin: "<5 m/s", frequency: 8 },
    { wind_dir_bin: "NW", wind_speed_bin: "5-8 m/s", frequency: 3 }
  ];

  // Lifecycle-Hook, um Daten beim Laden der Komponente abzurufen und regelmäßig zu aktualisieren
  useEffect(() => {
    fetchData(setData); // Abrufen der Daten beim Laden
    const interval = setInterval(() => {
      fetchData(setData); // Abrufen der Daten alle 30 Sekunden
    }, 30000);

    return () => clearInterval(interval); // Bereinigen des Intervalls beim Unmounten
  }, []);

  // Funktion zur dynamischen Darstellung der Inhalte basierend auf der aktiven Registerkarte
  const renderContent = () => {
    switch (activeTab) {
      case 'today':
        return <Today data={data} windroseData={windroseData} />;
      case 'temfeu':
        return <TemperaturFeuchte data={data} />;
      case 'niestra':
        return <NiederschlagStrahlung data={data} />;
      case 'kasten':
        return <Baukasten data={data} />;
      case 'test':
        return <div>Testraum.</div>;
      default:
        return null;
    }
  };

  // Rückgabe der App-Struktur
  return (
    <Layout>
      {/* Header mit dem Menü */}
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" /> {/* Platz für ein Logo */}
        <Menu
          theme="dark" // Dunkles Farbschema
          mode="horizontal" // Horizontale Navigation
          defaultSelectedKeys={['today']} // Standardmäßig ausgewählte Registerkarte
          items={items} // Definierte Menü-Items
          onClick={handleTabChange} // Klick-Handler für Registerkartenwechsel
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>

      {/* Dynamisch gerenderter Inhalt basierend auf der aktiven Registerkarte */}
      {renderContent()}
    </Layout>
  );
};

export default App; // Export der Hauptkomponente
