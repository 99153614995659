import React from 'react';
import Plot from 'react-plotly.js';
import './App.css';

const NiederschlagStrahlung = ({ data }) => {
  // Daten vorbereiten

  return (
    <div>
    <h2>Niederschlag & Strahlung</h2>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {/* Temperatur-Plot */}
      <Plot
        data={[
          { type: 'bar', mode: 'lines+markers', x: data.x, y: data.rain, marker: { color: 'blue' }, name: 'Niederschlag' }
        ]}
        layout={{ title: 'Niederschlag über die Zeit', xaxis: { title: 'Datum' }, yaxis: { title: 'Niederschlag mm' } }}
      />
      {/* Feuchte-Plot */}
      <Plot
        data={[
          { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.temperature, marker: { color: 'yellow' }, name: 'Strahlung' }
        ]}
        layout={{ title: 'Strahlung über die Zeit', xaxis: { title: 'Datum' }, yaxis: { title: 'Strahlung (W/m^2)' } }}
      />
    </div>
  </div>
  );
};

export default NiederschlagStrahlung;
