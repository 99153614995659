import React from 'react';
import Plot from 'react-plotly.js';
import './App.css';

const TemperaturFeuchte = ({ data }) => {
  // Daten vorbereiten

  return (
    <div>
    <h2>Temperatur & Feuchte</h2>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {/* Temperatur-Plot */}
      <Plot
        data={[
          { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.temperature, marker: { color: 'blue' }, name: 'Temperatur' }
        ]}
        layout={{ title: 'Temperatur über die Zeit', xaxis: { title: 'Datum' }, yaxis: { title: 'Temperatur (°C)' } }}
      />
      {/* Feuchte-Plot */}
      <Plot
        data={[
          { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.humidity, marker: { color: 'green' }, name: 'Feuchte' }
        ]}
        layout={{ title: 'Feuchte über die Zeit', xaxis: { title: 'Datum' }, yaxis: { title: 'Feuchte (%)' } }}
      />
    </div>
  </div>
  );
};

export default TemperaturFeuchte;
