import React from 'react';
import Plot from 'react-plotly.js';
import './App.css';
import Windrose from './Windrose.js';
//import './styles/weather-icons.css';
import moment from 'moment';
import { sum, to } from 'mathjs'

const Today = ({ data,windroseData }) => {

console.log(data)

  return (
    <div className='container'>
         
    <div className='Overview basic-card'>
    <div className='topline-summary topline'>
      <div className='topline-summary-left'>
        Aktuelles Wetter
      </div>
      <div className='topline-summary-right'>
      {moment(data.x[data.x.length - 1]).format("DD.MM.YYYY HH:mm:ss")}
      </div>

    </div>
    <div className='Overview-content'>
      <div className='summary-symbol-temp'>
        <div className='summary-symbol'>
          <i className='wi wi-day-rain-wind'></i>
        </div>
        <div className='summary-temp'>
          <div className='summary-temp-temp'>
            {data.temperature[data.x.length - 1]}°C
          </div>
          <div className='summary-temp-weather'>
            regnerisch
          </div>
          <div className='summary-temp-min-max'>
            <div className='summary-temp-min'>
            {Math.min(...data.temperature)}° |
            </div>
            <div className='summary-temp-max'>
            {Math.max(...data.temperature)}°
            </div>
          </div>
        </div>

      </div>
      <div className='summary-values'>
        <div className='summary-values-wind'>
          <div className='summary-value'>
          {data.wind[data.x.length - 1]}m/s
          </div>
          <div className='summary-unit'>
            Wind
          </div>

        </div>
        <div className='summary-values-rain'>
          <div className='summary-value'>
            {data.rain[data.x.length - 1]}mm
            </div>
            <div className='summary-unit'>
              Niederschlag
            </div>
        </div>
        <div className='summary-values-humid'>
          <div className='summary-value'>
            {Math.round(100*data.humidity[data.x.length - 1])}%
            </div>
            <div className='summary-unit'>
              Feuchte
            </div>
          </div>
      </div>
    </div>

  </div>
  <div className='Rain-and-Wind'>
    <div className='Rain basic-card'>
      <div className='topline'>
        Niederschlag
      </div>
      <div className='rain-values-graph'>

        <div className='summary-values rain-values'>
          < div className='summary-values-rain'>
            <div className='rain-value'>
            {sum(data.rain)*0.01} mm 
            </div>
            <div className='rain-unit'>
              Summe
            </div>

          </div>
          < div className='summary-values-rain'>
            <div className='rain-value'>
              {data.rain[data.x.length - 1]} mm
            </div>
            <div className='rain-unit'>
              Aktuell
            </div>
          </div>
          < div className='summary-values-rain'>
            <div className='rain-value'>
              {data.rain[data.x.length - 1]} mm
            </div>
            <div className='rain-unit'>
              Tendenz
            </div>

          </div>

        </div>
        <div className="rain-graphbox" style={{ position: 'relative' }}>
          <Plot
            id="overrain"
            data={[
              { 
                type: 'bar', 
                x: data.x,
                y: data.rain, 
                marker: { color: 'blue' }, 
                name: 'Temperatur'
              }]}
            layout={{
              autosize: true,
              margin: {
                t: 5,
                l: 5,
                r: 5,
                b: 20
              },
              paper_bgcolor:'rgba(0,0,0,0)',
              plot_bgcolor:'rgba(0,0,0,0)',
            }}
            config={{
              staticPlot: true
            }}
            style={{
              height: '100%',
              marginTop: '5px',
              marginLeft: '5px',
              marginRight: '5px',
            }}
            className="rain-graph"
          />
        </div>

      </div>
    
    </div>
    <div className='Wind basic-card'>
      <div className='topline'>
        Wind
      </div>
      <div className='rain-values-graph'>

        <div className='summary-values rain-values'>
          < div className='summary-values-rain'>
            <div className='rain-value'>
              {Math.max(...data.wind)} m/s
            </div>
            <div className='rain-unit'>
              Maximum
            </div>
          </div>
          < div className='summary-values-rain'>
            <div className='rain-value'>
              {data.wind[data.x.length - 1]} m/s
            </div>
            <div className='rain-unit'>
              Aktuell
            </div>

          </div>
          < div className='summary-values-rain'>
            <div className='rain-value'>
              {data.boen[data.x.length - 1]} m/s
            </div>
            <div className='rain-unit'>
              Böen
            </div>

          </div>

        </div>
        <div className="rain-graphbox" style={{ position: 'relative' }}>
        <Windrose windroseData={windroseData} />
        </div>

</div>
    </div>
  </div>
  <div className='Quadview'>
    <div className='Temp basic-card'>
      <div className='topline'>
        Temperatur
      </div>
      <div className='temp-content'>
        <div className='summary-values temp-values'>
          <div className='tempe-values'>
            <div className='temp-value'>
            {data.temperature[data.x.length - 1]} °C
            </div>
            <div className='temp-unit'>
              Aktuell
            </div>
          </div>
          <div className='tempe-values'>
            <div className='temp-value'>
            {data.temperature[data.x.length - 1]} °C
            </div>
            <div className='temp-unit'>
              Abweichung
            </div>
          </div>
          <div className='tempe-values'>
            <div className='temp-value'>
            {data.temperature[data.x.length - 1]} °C
            </div>
            <div className='temp-unit'>
              Abweichung
            </div>
          </div>
          <div className='temp-min-max'>
            <div className='temp-min'>
            {Math.min(...data.temperature)}° |
            </div>
            <div className='temp-max'>
            {Math.max(...data.temperature)}°
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='Strahlung basic-card'>
      <div className='topline'>
        Strahlung
      </div>
      <div className='summary-values rad-values'>
        <div className='rade-values'>
          <div className='rad-value'>
          12 W/m^2
          </div>
          <div className='temp-unit'>
            Globale Strahlung
          </div>
        </div>
        <div className='rade-values'>
          <div className='rad-value'>
          12 W/m^2
          </div>
          <div className='temp-unit'>
            Diffuse Strahlung
          </div>
        </div>
        <div className='rade-values'>
          <div className='rad-value'>
          12 
          </div>
          <div className='temp-unit'>
            UVI 
          </div>
        </div>
      </div>
    </div>
    <div className='Feuchte basic-card'>
      <div className='topline'>
        Feuchte
      </div>
      <div className='temp-content'>
        <div className='summary-values temp-values'>
          <div className='tempe-values'>
            <div className='temp-value'>
            {Math.round(100*data.humidity[data.x.length - 1])}%
            </div>
            <div className='temp-unit'>
              Aktuell
            </div>
          </div>
          <div className='tempe-values'>
            <div className='temp-value'>
            {data.temperature[data.x.length - 1]} °C
            </div>
            <div className='temp-unit'>
              Taupunkt
            </div>
          </div>
          <div className='tempe-values'>
            <div className='temp-value'>
            {data.humidity[data.x.length - 1]} %
            </div>
            <div className='temp-unit'>
              Abweichung
            </div>
          </div>
          <div className='temp-min-max'>
            <div className='temp-min'>
            {Math.round(100*Math.min(...data.humidity))}% |
            </div>
            <div className='temp-max'>
            {Math.round(100*Math.max(...data.humidity))}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='Luftdruck basic-card'>
      <div className='topline'>
        Luftdruck
      </div>
     
      <Plot
data={[
  { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.temperature, marker: { color: 'blue' }, name: 'Temperatur' }
]} // Hier kannst du deine Daten einfügen
layout={{
  autosize: true,
  margin: {
    t: 0,
    l: 0,
    r: 0,
    b: 0
  },
  paper_bgcolor:'rgba(0,0,0,0)',
  plot_bgcolor:'rgba(0,0,0,0)',
}}
config={{
  staticPlot: true
}}
style={{
  height: '100%',
  marginTop: '0',
  marginLeft: '0',
  marginRight: '0',
}}
className="press_gauge"
/>

    </div>
  </div>
  <div className='Webcam basic-card'>
  <img src={`${process.env.PUBLIC_URL}/Four_white_goose.JPG`} className="webcam_image" />

    
  </div>
  <div className='Graph basic-card'>
  <Plot
data={[
  { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.temperature, marker: { color: 'blue' }, name: 'Temperatur' }
]} // Hier kannst du deine Daten einfügen
layout={{
  autosize: true,
  margin: {
    t: 5,
    l: 5,
    r: 5,
    b: 20
  },
  paper_bgcolor:'rgba(0,0,0,0)',
  plot_bgcolor:'rgba(0,0,0,0)',
}}
config={{
  staticPlot: false
}}
style={{
  height: '100%',
  marginTop: '5px',
  marginLeft: '5px',
  marginRight: '5px',
}}
className="rain-graph"
/>
  </div>
</div>

  );
};

export default Today;
