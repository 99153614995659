import React, { useState } from "react";
import { Select, DatePicker, Button,Space } from "antd";
import Plot from "react-plotly.js";
import moment from "moment";

import { saveAs } from "file-saver"; // Bibliothek zum Download

const { RangePicker } = DatePicker;

const Baukasten = ({ data }) => {
    const [selectedY1, setSelectedY1] = useState("Temperatur");
    const [selectedY2, setSelectedY2] = useState("Wind");
    const [dateRange, setDateRange] = useState([null, null]);
  
    // Daten in ein Array von Objekten umwandeln
    const processedData = data.x.map((time, index) => ({
      Datum: time,
      Temperatur: data.temperature[index],
      Feuchte: data.humidity[index],
      Regen: data.rain[index],
      Wind: data.wind[index],
      Windmax: data.boen[index],
    }));
  
    // Filterdaten basierend auf dem ausgewählten Datum
    const filteredData = processedData.filter((row) => {
      const rowDate = moment(row.Datum);
      if (!dateRange[0] || !dateRange[1]) return true;
      return rowDate.isBetween(dateRange[0], dateRange[1], "minute", "[]");
    });
  
    // CSV-Erzeugung und Download
    const downloadCSV = () => {
      const csvHeaders = "Datum,Temperatur,Feuchte,Regen,Wind,Windmax\n";
      const csvRows = filteredData.map((row) =>
        [
          moment(row.Datum).format("YYYY-MM-DD HH:mm:ss"),
          row.Temperatur,
          row.Feuchte,
          row.Regen,
          row.Wind,
          row.Windmax,
        ].join(",")
      );
      const csvContent = csvHeaders + csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, "filtered_data.csv");
    };
  
    // Plot-Daten vorbereiten
    const plotData = [
      {
        x: filteredData.map((row) => row.Datum),
        y: filteredData.map((row) => row[selectedY1]),
        type: "scatter",
        mode: "lines+markers",
        name: selectedY1,
      },
      {
        x: filteredData.map((row) => row.Datum),
        y: filteredData.map((row) => row[selectedY2]),
        type: "scatter",
        mode: "lines+markers",
        name: selectedY2,
      },
    ];
  
    return (
      <div>
        <h2>Datenvisualisierung</h2>
  
        {/* Dropdown-Menüs zur Auswahl der Y-Achsen-Werte */}
        <label>
          Y1:
          <select onChange={(e) => setSelectedY1(e.target.value)} value={selectedY1}>
            <option value="Temperatur">Temperatur</option>
            <option value="Feuchte">Feuchte</option>
            <option value="Regen">Regen</option>
            <option value="Wind">Wind</option>
            <option value="Windmax">Windmax</option>
          </select>
        </label>
        <label>
          Y2:
          <select onChange={(e) => setSelectedY2(e.target.value)} value={selectedY2}>
            <option value="Temperatur">Temperatur</option>
            <option value="Feuchte">Feuchte</option>
            <option value="Regen">Regen</option>
            <option value="Wind">Wind</option>
            <option value="Windmax">Windmax</option>
          </select>
        </label>
  
        {/* Datepicker zur Auswahl des Datumsbereichs */}
        <Space direction="vertical" style={{ marginTop: "10px", marginBottom: "10px" }}>
          <RangePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(dates) => {
              setDateRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null]);
            }}
          />
        </Space>
  
        {/* Plot */}
        <div> 
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Plot
          data={plotData}
          layout={{
            title: "Zeitreihe",
            xaxis: { title: "Zeit" },
            yaxis: { title: selectedY1 },
            yaxis2: { title: selectedY2, overlaying: "y", side: "right" },
            legend : {
                orientation:"h",
                yanchor: "bottom",
            },
          }}
        />
        </div>
        </div>
        {/* Download-Button */}
        <Button type="primary" style={{ marginTop: "10px" }} onClick={downloadCSV}>
          Daten als CSV herunterladen
        </Button>
      </div>
    );
  };

export default Baukasten;
