import moment from 'moment';
import * as d3 from 'd3';

export const fetchData = (setData) => {
  d3.dsv(";", `${process.env.PUBLIC_URL}/data.csv`)
    .then((csvData) => {
      // Spaltennamen bereinigen
      const cleanCsvData = csvData.map(row => {
        const cleanRow = {};
        Object.keys(row).forEach(key => {
          const trimmedKey = key.trim(); // Leerzeichen entfernen
          cleanRow[trimmedKey] = row[key]; // Bereinigte Keys übernehmen
        });
        return cleanRow;
      });
      // Ausgabe der bereinigten Spaltennamen
      const cleanedColumns = Object.keys(cleanCsvData[0]);  // Die bereinigten Keys des ersten Datensatzes
      console.log('Bereinigte Spaltennamen:', cleanedColumns);
      // Daten verarbeiten
      const processedData = cleanCsvData.map(row => {
        row['Temperatur'] = parseFloat(row['Temperatur']);
        row['Feuchte'] = parseFloat(row['Feuchte']);
        row['Regen'] = parseFloat(row['Regen']);
        row['Wind (m/s)'] = parseFloat(row['Wind (m/s)']);
        row['Windmax (m/s)'] = parseFloat(row['Windmax (m/s)']);
        return row;
      });

      const dates = processedData.map(row => moment(row['Datum/Zeit'], "DD.MM.YYYY HH:mm:ss").toDate());
      const temperatures = processedData.map(row => row['Temperatur']);
      const humidity = processedData.map(row => row['Feuchte']);
      const rain = processedData.map(row => row['Regen']);
      const wind = processedData.map(row => row['Wind (m/s)']);
      const boen = processedData.map(row => row['Windmax (m/s)']);

      setData({ x: dates, temperature: temperatures, humidity: humidity, rain: rain, wind: wind, boen: boen });
    })
    .catch(error => {
      console.error("Fehler beim Laden der CSV-Datei:", error);
    });
};
