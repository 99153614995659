import React from 'react';
import Plot from 'react-plotly.js';

const Windrose = ({ windroseData }) => {
  // Daten vorbereiten
  const speedCategories = ["<5 m/s", "5-8 m/s", "8-10 m/s", ">10 m/s"];
  const directionLabels = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

  // Frequenzdaten für jede Geschwindigkeitskategorie sammeln
  const frequenciesBySpeed = speedCategories.map(speedCategory =>
    directionLabels.map(direction => {
      const entry = windroseData.find(
        d => d.wind_dir_bin === direction && d.wind_speed_bin === speedCategory
      );
      return entry ? entry.frequency : 0; // 0, wenn keine Daten vorhanden sind
    })
  );

  // Erstelle die Windrose-Traces
  const traces = speedCategories.map((speedCategory, index) => ({
    r: frequenciesBySpeed[index], // Frequenzwerte für diese Kategorie
    theta: directionLabels,      // Windrichtungen
    //name: speedCategory,         // Legendenname
    type: 'barpolar'             // Polar-Balkendiagramm
  }));

  // Layout für die Windrose
  const layout = {

    showlegend: false,

    polar : {
        bgcolor : "rgba(0, 0, 0,0)",
        radialaxis: {ticks:'', showticklabels:false},
        angularaxis:{direction:'clockwise', showline:false}
    },
    autosize:true,
    margin:{
        l:0,
        r:0,
        b:0,
        t:0,
        pad:0
    }, 
    plot_bgcolor:"rgba(0,0,0,0)",
    paper_bgcolor:'rgba(0,0,0,0)',
  };

  return (
    <Plot
      data={traces}
      layout={layout}
      config={{
        staticPlot: true // Interaktivität deaktivieren
      }}
      style={{
        height: '100%',
        marginTop: '5px',
        marginLeft: '5px',
        marginRight: '5px'
      }}
      className="rain-graph"
    />
  );
};

export default Windrose;
